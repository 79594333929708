import UserRole from "@/scripts/models/userRole.model";
export default class UserPerson {

    constructor() {
        this.personId = 0;
        this.userId = 0;
        this.name = "";
        this.email = "";
        this.cpf = "";
        this.lastLogin = "";
        this.optin = null;
        this.currentUserRole = new UserRole();
        this.entitiesOccupation = [];
        this.userPermissionsEntities = [];
    }

}