<template>
  <v-dialog v-model="dialog" scrollable max-width="900px">
    <v-card v-if="dialog">
      <v-card-title class="headline">
        <h1>
          {{
            userHasPermissions || userAlreadyExists || editingMode
              ? "Editar"
              : "Convidar"
          }}
          Usuário
        </h1>
        <v-btn class="close-modal" @click="close">
          <i class="fal fa-times"></i>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 550px">
        <v-layout w100 d-flex>
          <div>
            <v-card-text style="height: 470px">
              <v-layout pa-4 wrap class="list-scroll">
                <v-flex
                  xs12
                  v-if="
                    currentEntity.type == entityType.AUC &&
                    (!editingMode || signedUserEntityType == entityType.AUC)
                  "
                >
                  <InputSelect
                    ref="entityTypeInput"
                    :items="entityTypeList"
                    v-model="selectedEntityType"
                    valueAttribute="id"
                    textAttribute="name"
                    textLabel="Tipo de Perfil"
                    helpLabel="Instituição, Empresa, Conselho"
                    invalidInputMessage="Selecione um perfil para o usuário"
                    :useAnotherDefaultValue="true"
                  ></InputSelect>
                </v-flex>
                <v-flex xs12>
                  <InputText
                    ref="cpfInput"
                    type="tel"
                    v-model="userPerson.cpf"
                    textLabel="CPF"
                    mask="###.###.###-##"
                    :focusOnCreate="true"
                    :disabled="editingMode"
                    :validation="validateCPF"
                    invalidInputMessage="Digite um CPF válido."
                    @input="findByCPF($event)"
                  />
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    selectedEntityType == entityType.Company &&
                    currentEntity.type == entityType.AUC
                  "
                >
                  <InputSelect
                    ref="companyGroupInput"
                    :items="companyGroupList"
                    v-model="selectedCompanyGroup"
                    valueAttribute="id"
                    textAttribute="name"
                    textLabel="Grupo Econômico"
                    invalidInputMessage="Selecione um grupo econômico"
                    @input="changeCompanyGroup()"
                  ></InputSelect>
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    selectedEntityType == entityType.Company &&
                    currentEntity.type == entityType.AUC
                  "
                >
                  <InputSelect
                    ref="companyInput"
                    :items="companyList"
                    v-model="userPerson.currentUserRole.entityId"
                    valueAttribute="entityId"
                    textAttribute="entity.name"
                    textLabel="Empresa"
                    invalidInputMessage="Selecione uma empresa"
                  ></InputSelect>
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    selectedEntityType == entityType.Institution &&
                    currentEntity.type == entityType.AUC
                  "
                >
                  <InputSelect
                    ref="instituionInput"
                    :items="instituionList"
                    v-model="userPerson.currentUserRole.entityId"
                    valueAttribute="entity.id"
                    textAttribute="entity.name"
                    textLabel="Instituições"
                    invalidInputMessage="Selecione uma instituição"
                  ></InputSelect>
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    selectedEntityType == entityType.Council &&
                    currentEntity.type == entityType.AUC
                  "
                >
                  <InputSelect
                    ref="councilInput"
                    :items="councilList"
                    v-model="userPerson.currentUserRole.entityId"
                    valueAttribute="entity.id"
                    textAttribute="entity.name"
                    textLabel="Conselho"
                    invalidInputMessage="Selecione um conselho"
                  ></InputSelect>
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    userPerson.currentUserRole.occupation ||
                    selectedEntityType == entityType.Institution ||
                    selectedEntityType == entityType.Council
                  "
                >
                  <InputSelect
                    ref="userOccupationInput"
                    :items="userOccupationList"
                    v-model="userPerson.currentUserRole.occupation"
                    valueAttribute="id"
                    textAttribute="name"
                    textLabel="Cargo"
                    invalidInputMessage="Selecione um cargo"
                  ></InputSelect>
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    userPerson.currentUserRole.occupation ==
                    userOccupation.Representative
                  "
                >
                  <InputText
                    ref="occupationDescInput"
                    type="text"
                    v-model="userPerson.currentUserRole.occupationDesc"
                    textLabel="Descrição do cargo"
                    invalidInputMessage="Especifique o cargo"
                  />
                </v-flex>
                <v-flex
                  xs12
                  mt-4
                  v-if="
                    userPerson.currentUserRole.occupation ==
                      userOccupation.President ||
                    userPerson.currentUserRole.occupation ==
                      userOccupation.Representative
                  "
                >
                  <InputDate
                    ref="expirationDateInput"
                    v-model="userPerson.currentUserRole.expirationDate"
                    textLabel="Data de Vigência"
                    placeholder="__/__/____"
                    invalidInputMessage="Selecione uma data de vigência maior que a atual"
                    :validation="validateExpirationDate"
                  ></InputDate>
                </v-flex>
                <v-flex xs12>
                  <InputText
                    ref="nameInput"
                    type="text"
                    v-model="userPerson.name"
                    textLabel="Nome"
                    :disabled="userAlreadyExists || editingMode"
                    invalidInputMessage="Digite o nome do usuário"
                  />
                </v-flex>
                <v-flex xs12>
                  <InputText
                    ref="emailInput"
                    type="text"
                    v-model="userPerson.email"
                    textLabel="E-mail"
                    :validation="validateEmail"
                    :disabled="!(isUserFromAUC)"
                    invalidInputMessage="Digite um e-mail"
                  />
                </v-flex>
                <v-flex xs12>
                  <InputSelect
                    ref="roleInput"
                    :items="roles"
                    v-model="userPerson.currentUserRole.roleId"
                    valueAttribute="id"
                    textAttribute="description"
                    textLabel="Perfil do Usuário"
                    invalidInputMessage="Escolha o perfil de acesso"
                  ></InputSelect>
                </v-flex>
                <template v-if="editingMode || userHasPermissions">
                  <hr class="md my-0" />
                  <v-flex xs12>
                    <v-checkbox
                      v-model="userPerson.currentUserRole.active"
                      label="Ativo"
                    ></v-checkbox>
                  </v-flex>
                </template>
                <template>
                  <hr class="md my-0" />
                  <v-flex xs12 d-flex>
                    <InputCheckBoxList
                      ref="permissionsList"
                      :helpBoxText="''"
                      :list="enabledPermissionList"
                      v-model="userPerson.userPermissionsEntities"
                      labelAttribute="rule"
                      valueAttribute="id"
                      :columns="3"
                      :required="false"
                    />
                  </v-flex>
                </template>
              </v-layout>
            </v-card-text>
            <v-card-actions class="pa-0">
              <div class="footer-modal-atividades">
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn btn-default px-5"
                  large
                  @click="close"
                  >Cancelar</v-btn
                >
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn btn-primary px-5"
                  large
                  @click="isValid"
                  >{{
                    userHasPermissions || userAlreadyExists || editingMode
                      ? "Salvar"
                      : "Enviar Convite"
                  }}</v-btn
                >
              </div>
            </v-card-actions>
          </div>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

  
<script type="plain/text">
import UserPerson from "@/scripts/models/userPerson.model";
import UserService from "@/scripts/services/user.service";
import CompanyGroupService from "@/scripts/services/companyGroup.service";
import PersonService from "@/scripts/services/person.service";
import InstitutionService from "@/scripts/services/institution.service";
import CouncilService from "@/scripts/services/council.service";
import PermissionService from "@/scripts/services/permission.service";
import { mapGetters } from "vuex";
import Entity from "@/scripts/models/entity.model";
import Permissions from "@/scripts/models/enums/permissions.enum";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import { parse } from "date-fns";
import {
  EntityType,
  EntityTypeList,
} from "@/scripts/models/enums/entityType.enum.js";
import {
  UserOccupation,
  UserOccupationList,
} from "@/scripts/models/enums/userOccupation.enum.js";

export default {
  extends: ValidatableComponent,
  props: ["modal-title", "entityId"],
  data() {
    return {
      dialog: false,
      signedUserEntityType: null,
      userPerson: new UserPerson(),
      userService: new UserService(),
      personService: new PersonService(),
      companyGroupService: new CompanyGroupService(),
      institutionService: new InstitutionService(),
      councilService: new CouncilService(),
      permissionService: new PermissionService(),
      currentEntity: new Entity(),
      entity: new Entity(),
      originalUserPerson: null,
      selectedCompanyGroup: null,
      selectedEntityType: null,
      editingMode: false,
      userAlreadyExists: false,
      userHasPermissions: false,
      instituionList: [],
      companyGroupList: [],
      companyList: [],
      councilList: [],
      roles: [],
      userPersonFound: null,
      listPermissions: null,
      entityType: EntityType,
      entityTypeList: EntityTypeList,
      userOccupation: UserOccupation,
      userOccupationList: UserOccupationList,
      hasPresidentAtEntity: false,
      hasRepresentativeAtEntity: false,
      isUserFromAUC: false,
      loggedUser: { }
    };
  },
  computed: {
    ...mapGetters(["hasPermission"]),

    enabledPermissionList() {
      return this.listPermissions.filter(function (item) {
        return item.canShow;
      });
    },
  },
  created() {
    this.showValidationFailMessageMethod = this.showFailMessage;
    this.onValidationSuccessMethod = this.validationSuccess;
    this.validationMethod = this.validate;
    this.permissionService.listAll().then(
      function (data) {
        let permissionsToShow = [
          Permissions.ConciliationRH,
          Permissions.CashFlow,
          Permissions.CanSignReceipt,
        ];
        data.map(function (item) {
          item.canShow = false;
          if (
            permissionsToShow.includes(item.id) &&
            item.id != Permissions.CanSignReceipt
          ) {
            item.canShow = true;
          }
        });
        this.listPermissions = data.filter((item) =>
          permissionsToShow.includes(item.id)
        );
      }.bind(this)
    );
    this.userService.getEntityById(this.entityId).then(
      function (data) {
        if (!data) return;
        this.currentEntity = data;
        if (this.currentEntity.id != 0) {
          if (this.currentEntity.type == EntityType.AUC) {
            this.companyGroupService.listAllCompanyGroup(
              this.listAllCompanyGroupCallback
            );
            this.institutionService.listAll(this.listAllInstituionCallback);
            this.councilService.listAll(this.listAllCouncilCallback);
          } else {
            this.selectedEntityType = this.currentEntity.type;
          }
        }
      }.bind(this)
    );
  },
  watch: {
    userHasPermissions: {
      handler: function (after, before) {
        if (after) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "O usuário atual já tem permissão para essa entidade."
          );
        }
      },
      deep: true,
    },
    selectedEntityType: {
      handler: function () {
        if (this.selectedEntityType == EntityType.AUC) {
          this.userPerson.currentUserRole.entityId = this.currentEntity.id;
        }
      },
    },
    "userPerson.currentUserRole.occupation": {
      handler: function (value) {
        //ao alterar a ocupação para representante, mostra-se a opcao de assinar recibos
        if ([UserOccupation.Representative].includes(parseInt(value))) {
          this.listPermissions.map(function (item) {
            if (item.id == Permissions.CanSignReceipt) {
              item.canShow = true;
            }
            return item;
          });
          //caso contrario remove a opcao de assinar recibos
        } else {
          this.listPermissions.map(function (item) {
            if (item.id == Permissions.CanSignReceipt) {
              item.canShow = false;
            }
            return item;
          });
        }
      },
      deep: true,
    },
    "userPerson.currentUserRole.entityId": {
      handler: function (after, before) {
        if (this.userPerson.entitiesOccupation) {
          var found = this.userPerson.entitiesOccupation.find(
            (item) => item.entityId == after
          );
          if (found && !this.editingMode) {
            this.userHasPermissions = true;
            this.userPerson.personId = 0;
            this.userPerson.userId = 0;
            this.userPerson.currentUserRole.userRoleId = found.userRoleId;
            this.userPerson.currentUserRole.roleId = found.roleId;
            this.userPerson.currentUserRole.occupation = found.occupation;
            this.userPerson.currentUserRole.occupationDesc =
              found.occupationDesc;
            this.userPerson.currentUserRole.expirationDate =
              found.expirationDate;
            this.userPerson.currentUserRole.active = found.active;
          }
        }

        this.personService.getRolesByEntity(after).then(
          function (data) {
            this.roles = data;
          }.bind(this)
        );
        this.personService
          .listPermissionIdInUserPermissionEntityByEntityId(
            after,
            this.userPerson.userId
          )
          .then(
            function (data) {
              this.userPerson.userPermissionsEntities = data;
            }.bind(this)
          );

        this.personService
          .existsPresidentByEntity(after)
          .then(this.callbackHasPresident);
        this.personService
          .existsRepresentativeByEntity(after)
          .then(this.callbackHasRespentative);
      },
      deep: true,
    },
  },
  methods: {
    callbackHasPresident(data) {
      this.userOccupationList.forEach(function (e) {
        if (e.id == UserOccupation.President) {
          e.active = !data;
        }
      });
      this.hasPresidentAtEntity = data;
    },
    callbackHasRespentative(data) {
      /* this.userOccupationList.forEach(function(e){
        if(e.id == UserOccupation.Representative){
          e.active = !data;
        }
      }); */
      this.hasRepresentativeAtEntity = data;
    },
    findByCPF(currentCPF) {
      if (this.userAlreadyExists && currentCPF.length != 11) {
        this.userAlreadyExists = false;
        this.userPerson.name = "";
        this.userPerson.email = "";
      }

      if (!this.editingMode && this.dialog) {
        if (currentCPF.length == 11) {
          this.personService.findByCPF(currentCPF, this.currentEntity.id).then(
            function (data) {
              // Verifica se o usuário existe
              if (data && data.optin) {
                this.userAlreadyExists = true;
                this.userPerson.optin = data.optin;
                this.userPersonFound = data;
                this.userPerson.personId = data.personId;
                this.userPerson.userId = data.userId;
                this.userPerson.name = data.name;
                this.userPerson.email = data.email;
                this.userPerson.entitiesOccupation = data.entitiesOccupation;

                // Se o usuário encontrado já tem regras para entidade atual
                if (this.userPersonFound.currentUserRole) {
                  this.userHasPermissions = true;
                  this.selectedEntityType = this.currentEntity.type;
                  this.userPerson.currentUserRole.occupation = this.userPersonFound.currentUserRole.occupation;
                  this.userPerson.currentUserRole.occupationDesc = this.userPersonFound.currentUserRole.occupationDesc;
                  this.userPerson.currentUserRole.expirationDate = this.userPersonFound.currentUserRole.expirationDate;
                  this.userPerson.currentUserRole.entityId = this.userPersonFound.currentUserRole.entityId;
                  this.userPerson.currentUserRole.roleId = this.userPersonFound.currentUserRole.roleId;
                  this.userPerson.currentUserRole.active = this.userPersonFound.currentUserRole.active;
                } else {
                  this.userPerson.currentUserRole.roleId = 0;
                  this.userPerson.currentUserRole.occupation = 0;
                  this.userPerson.currentUserRole.occupationDesc = "";
                  this.userPerson.currentUserRole.expirationDate = "";
                }
              } else {
                this.clearForm();
                this.userPerson.optin = data ? data.optin : null;
                this.userHasPermissions = false;
                this.userAlreadyExists = false;
                this.editingMode = false;
              }
            }.bind(this)
          );
        }
      }
    },
    listAllCompanyGroupCallback(data) {
      this.companyGroupList = data;
    },
    listAllInstituionCallback(data) {
      this.instituionList = data;
    },
    listAllCouncilCallback(data) {
      this.councilList = data;
    },
    changeCompanyGroup() {
      if (this.selectedCompanyGroup != 0) {
        var find = this.companyGroupList.filter(
          function (item) {
            return item.id == this.selectedCompanyGroup;
          }.bind(this)
        );
        if (find) {
          this.companyList = find[0].companies;
        } else {
          this.companyList = [];
        }
      } else {
        this.companyList = [];
      }
    },
    save() {
      if (
        this.userPerson.currentUserRole.occupation == UserOccupation.RegularUser
      ) {
        this.userPerson.currentUserRole.occupationDesc = null;
        this.userPerson.currentUserRole.expirationDate = null;
      } else if (
        this.userPerson.currentUserRole.occupation == UserOccupation.President
      ) {
        this.userPerson.currentUserRole.occupationDesc = null;
      }

      this.originalUserPerson.personId = this.userPerson.personId;
      this.originalUserPerson.email = this.userPerson.email;
      this.personService.saveUserPerson(this.originalUserPerson).then(
        function (data) {
          this.$emit("refresh");
        }.bind(this)
      );
    },
    open(item, signedUserEntityType) {
      this.signedUserEntityType = signedUserEntityType;
      this.dialog = true;
      this.originalUserPerson = item;
      if (item.personId) this.editingMode = true;
      else if (this.currentEntity.type != EntityType.AUC) {
        this.originalUserPerson.currentUserRole.entityId = this.currentEntity.id;
      }
      this.copy(this.originalUserPerson, this.userPerson);
      this.personService.getLoggedPersonUser(this.entityId).then(
			function(data) {
        debugger
				if (data) {
          this.isUserFromAUC = data.entitiesOccupation.some(x => x.entityId === 6);
        }
			}.bind(this)
		);
    },
    close() {
      this.dialog = false;
      this.selectedCompanyGroup = null;
      this.userHasPermissions = false;
      this.userAlreadyExists = false;
      this.editingMode = false;
      //this.copy(new UserPerson(), this.userPerson);
    },
    clearForm() {
      this.userPerson.currentUserRole.roleId = 0;
      this.userPerson.currentUserRole.occupation = 0;
      // this.userPerson.currentUserRole.active = false;
      this.userPerson.currentUserRole.occupationDesc = "";
      this.userPerson.currentUserRole.expirationDate = "";
      if (this.selectedEntityType == EntityType.AUC) {
        this.userPerson.currentUserRole.entityId = this.currentEntity.id;
      }
    },
    copy(from, to) {
      to.personId = from.personId;
      to.userId = from.userId;
      to.name = from.name;
      to.email = from.email;
      to.cpf = from.cpf;
      to.optin = from.optin;
      to.currentUserRole = from.currentUserRole;
      to.entitiesOccupation = from.entitiesOccupation;
      to.userPermissionsEntities = from.userPermissionsEntities;
    },
    validateCPF() {
      if (this.userPerson.cpf) {
        return CommonHelper.validateCPF(this.userPerson.cpf);
      }
    },
    validateEmail() {
      return CommonHelper.validateEmail(this.userPerson.email);
    },
    validateExpirationDate() {
      var current = parse(this.userPerson.currentUserRole.expirationDate);
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      return current > today;
    },
    showFailMessage(invalidElements) {
      if (invalidElements.length >= 1) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Você não preencheu todos os campos"
        );
      }
    },
    validate(performMethods) {
      let valid = this.validateComponents(performMethods);
      if (!valid) return false;
      return true;
    },
    validationSuccess() {
      this.copy(this.userPerson, this.originalUserPerson);
      this.save();
      this.close();
    },
  },
};
</script>